/* PrivacyPolicyModal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: var(--background);
  padding: 20px;
  border-radius: 8px;
  max-width: 420px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

.modal-content p {
  line-height: 22px;
  font-size: 15px;
}

.modal-content a {
  color: var(--white);
}

.modal-content a:hover {
  color: var(--primary);
}

.open-modal-button, .close-modal-button {
  background-color: var(--black);
  color: var(--white);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  margin: 0 auto;
  display: block;
}
.open-modal-button, .close-modal-button:hover {
  background-color: var(--primary);
}

.close-modal-button {
  margin-top: 20px;
}

/* mobile  */
@media (max-width: 768px) {
  .modal-content {
    padding: 10px;
    max-width: 90%;
    height: 95%;
  }

  .modal-overlay {
    padding-top: 70px;
  }

  .open-modal-button, .close-modal-button {
    padding: 8px 16px;
  }
}