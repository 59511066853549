.spotlight-container {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  padding: 14px 20px;
  background: #1d1d1d;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 999;
  align-items: center;
  display: none;
  border: 2px solid var(--primary);
}

.spotlight-container .icon {
  font-size: 20px;
}

.spotlight-container.open {
  display: flex;
}

.clearHistory {
  color: var(--white);
  cursor: pointer;
  text-align: left;
  margin-top: 20px;
  gap: 8px;
  align-items: center;
}

.clearHistory:hover {
  color: var(--primary);
}

.spotlight-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  z-index: 999;
  display: none;
}

.spotlight-overlay.open {
  display: flex;
}

.return {
  position: absolute;
  top: 70px;
  right: 10px;
  color: #9b9b9b;
}

.return:hover {
  color: #fff;
  cursor: pointer;
}

/* mobile **/
@media (max-width: 768px) {
  .spotlight-container {
    width: 90%;
    padding: 14px 10px;
  }
  .spotlight-container .input {
    font-size: 16px;
  }
  .spotlight-container .icon {
    font-size: 22px;
  }
}