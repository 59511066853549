.browse-grid {
    padding-top: 50px; /* offset header height */
    padding-bottom: 80px; /* offsets the player */
    padding-left: 18px;
}

.album-art {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    background-size: cover;
    border-radius: 5px;
}

.album-art > .playback-controls {
    position: absolute; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    padding-top: 15%;
    justify-content: center;
    align-items: center;  /* centering the content */
    transition: 0.2s all ease-in-out;
}

.playback-controls .play-circle {
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: var(--white);
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s all ease-in-out;
    opacity: 0;
}

.playback-controls .play-circle:hover {
    border-color: transparent;
    background: linear-gradient(rgb(16, 22, 34), rgb(16, 22, 34)) padding-box padding-box, linear-gradient(81.02deg, rgb(250, 85, 96) -23.47%, rgb(177, 75, 244) 45.52%, rgb(77, 145, 255) 114.8%) border-box border-box;
    box-shadow: rgba(4, 0, 255, 0.6) 0px 0px 1rem;
}

.album-art:hover .playback-controls .play-circle,
.album-art.currently-playing .playback-controls .play-circle {
    opacity: 1;
    background: var(--black);
    cursor: pointer;
}

.album-art:hover .playback-controls,
.album-art.currently-playing .playback-controls {
    background: rgb(0 0 0 / 50%);
}

.album-art .playlist-title {
    opacity: 0;
    color: white;
    font-size: 20px;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 90%;
    overflow: hidden;
}

.album-art:hover .playlist-title,
.album-art.currently-playing .playlist-title {
    opacity: 1;
}

@media (max-width: 768px) {
    .features {
        grid-template-columns: repeat(1, 1fr);
    }
    .hero {
        padding: 10px;
    }
    .hero pm, .playlist-title {
        display: none;
    }
}

