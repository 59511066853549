.spotify-player {
  position: fixed;
  z-index: 10;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 85px;
  background-color: var(--player-background);
  box-shadow: 1px -2px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.spotify-player .name {
  display: flex;
  flex-direction: column;
}

.spotify-player .name .title {
  overflow: hidden;
  width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.spotify-player .name .artist {
  overflow: hidden;
  width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
  color: #b3b3b3;
}

.spotify-player .player-controls {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 15px;
}

.spotify-player .player-controls input[type="range"] {
  accent-color: var(--primary);
  cursor: pointer;
  width: 200px;
}

.spotify-player .player-controls input[type=range].vertical {
  position: fixed;
  bottom: 60px;
  right: 20px;
  z-index: 999;
  writing-mode: vertical-lr;
  direction: rtl;
  width: 16px;
  height: 200px;
  vertical-align: bottom;
}

.spotify-player .track-info {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.spotify-player img {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  cursor: pointer;
}

.spotify-player img:hover {
  border-color: transparent;
  box-shadow: rgba(4, 0, 255, 0.6) 0px 0px 1rem;
}

.track-info span {
  font-size: 15px;
  color: white;
  margin-right: 10px;
}

.player-play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-flex;
  align-items: center;
  gap: 15px;
}

.player-play svg:hover {
  fill: white;
  cursor: pointer;
}

.info-button {
  cursor: pointer;
}

/* mobile */
@media (max-width: 768px) {

  .spotify-player {
    padding: 10px;
  }

  .player-play {
    left: 45%;
  }

  .spotify-player .player-controls {
    padding: 0;
  }

  .spotify-player .track-info {
    padding: 0;
  }

  .spotify-player img {
    width: 50px;
    height: 50px;
  }

  .track-info span {
    display: none;
  }
  .spotify-player .favorite {
    display: none;
  }
  .spotify-player .player-controls input[type="range"] {
    width: 80px;
  }
}

/* large screens */
@media (min-width: 1024px) {
  .spotify-player .name .title, .spotify-player .name .artist {
    width: 300px;
  }
}

/* larger screens */
@media (min-width: 1420px) {
  .spotify-player .name .title, .spotify-player .name .artist {
    width: 500px;
  }
}
