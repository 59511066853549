body, html {
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
}

.landing {
    width: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 900px;
    margin: 0 auto;
  }
  
  .hero {
    width: 100%;
    text-align: center;
  }

  .hero p.small {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    font-size: 13px;
    margin: 0;
  }

  .hero.logged-in {
    padding-top: 3rem; 
}
  .hero img {
    margin-bottom: 1rem;
    width: 220px;
  }
  .hero h1 {
    font-size: 75px;
    margin: 0 0 20px;
  }
  .hero p {
    font-size: 20px;
    line-height: 26px;
    margin: 0 0 25px;
  }
  
  .spotifyBtn {
    background-color: #1DB954;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    cursor: pointer;
    margin-top: 2rem;
  }
  
  .features {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    justify-content: space-between;
    width: 100%;
    margin-top: 3rem;
    
  }
  
  .feature {
    text-align: center;
  }
  
  .featureIcon {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .about {
    width: 70%;
    margin-top: 1rem;
    text-align: center;
    background-size: cover;
    padding: 2rem;
  }
  
  footer {
    margin-top: auto;
    padding: 2rem 0;
    display: flex;
    gap: 2rem;
  }
  
  .about a, .about a:visited {
    color: var(--primary);
    text-decoration: none;
  }
  .about .github, .about .github:visited {
    color: white;
  }
  
  .about a:hover {
    text-decoration: underline;
  }
  
  .about .small {
    color:#ababab;
    font-size: 12px;
  }

  .instructions {
    display: flex;
    gap: 40px;
    padding: 25px 0;
  }

  .instructions .item {
    border-top: 2px solid #4a4a4a;
  }

  .instructions .step {
    font-size: 12px;
    color: #ababab;
  }

  .instructions h4 {
    font-size: 22px;
  }

  .landing .get-started {
    margin: 20px auto;
  }


  /* tablet */
  @media screen and (max-width: 1024px) {
    .landing {
      padding: 0 20px;
      max-width: 700px;
    }
  }

  /* mobile  */
  @media screen and (max-width: 768px) {
    .home .content {
      padding: 0;
    }
    .features {
      grid-template-columns: 1fr;
      padding: 0 20px;
    }
    .hero img {
      width: 150px;
    }
    .hero h1 {
      font-size: 50px;
    }
    .hero p {
      font-size: 16px;
    }
    .about {
      width: 90%;
    }
    .landing {
      padding: 0;
    }
    .instructions {
      flex-direction: column;
      width: 80%;
    }
    .instructions h4 {
      font-size: 18px;
    }
  }
  