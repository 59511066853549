.carousel-container {
  width: 80%;
  margin: 0 auto;
  padding: 0px 0 40px;
}

.carousel-container h2 {
  text-align: center;
  letter-spacing: 5px;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 20px;
  color: var(--link-color);
}

.carousel-item {
  position: relative;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  transform: scale(0.9);
  cursor: pointer;
}

.carousel-item:hover {
  transform: scale(1.00);
}

.carousel-item img, .carousel-item .skeleton {
  width: 100%;
  height: auto;
  border-radius: 999px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.carousel-item-content {
  position: relative;
}

.carousel-item-overlay {
  position: absolute;
  bottom: 0px;
  left: 20%;
  right: 20%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 100px;
  padding: 8px;
  opacity: 0;
  font-size: 12px;
  transition: opacity 0.3s ease-in-out;
}

.carousel-item-overlay h3, .carousel-item-overlay p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 auto;
  text-align: center;
}

.carousel-item:hover .carousel-item-overlay {
  opacity: 1;
}

.copied-tooltip {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--primary);
  color: var(--white);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9em;
}

.carousel-container .slick-dots li button:before {
  color: white;
  opacity: 0.45;
}

.carousel-container .slick-dots li.slick-active button:before {
  color: white;
  opacity: 1;
}