.toast {
    position: fixed;
    max-width: 300px;
    padding: 15px;
    border-radius: 5px;
    margin: 10px;
    color: #fff;
    z-index: 9999;
    height: 45px;
  }
  
  .toast-close {
    background: none;
    border: none;
    color: #fff;
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 20px;
    cursor: pointer;
  }
  
  .toast-success {
    background-color: var(--primary);
  }
  
  .toast-error {
    background-color: #BD362F;
  }
  
  .toast-info {
    background-color: #2F96B4;
  }
  
  .toast-warning {
    background-color: #F89406;
  }
  
  .toast-top-left {
    top: 10px;
    left: 10px;
  }
  
  .toast-top-right {
    top: 10px;
    right: 10px;
  }
  
  .toast-bottom-left {
    bottom: 10px;
    left: 10px;
    top: auto;
  }
  
  .toast-bottom-right {
    bottom: 10px;
    right: 10px;
    top: auto;
  }
  
  .toast-top-center {
    top: 10px;
    left: 55%;
    transform: translateX(-50%);
  }

  .toast-bottom-center {
    bottom: 90px;
    left: 50%;
    right: 0;
    top: auto;
    transform: translateX(-50%);
  }

  /* mobile */
  @media (max-width: 768px) {
    .toast {
      margin: 0;
      left: 45%;
    }
  }