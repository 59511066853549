:root {
  --drawer-width: 350px;
  --drawer-large-width: 420px;
  --drawer-small-width: 280px;
}

.drawer {
    position: fixed;
    right: 0;
    width: var(--drawer-width);
    height: calc(100% - 140px);
    top: 60px;
    background: var(--background);
    padding: 10px 20px;
    box-shadow: -1px 3px 8px #000;
    transform: translateX(100%);
    opacity: 0;
    transition: transform 0.2s ease-out, opacity 0.1s ease-out;
}

.drawer-open {
  padding-right: var(--drawer-width);
}

.drawer.show {
    transform: translateX(0);
    opacity: 1;
}

.drawer .track-info {
    display: flex;
    flex-direction: column;
}

.drawer-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.drawer-title h2 {
    font-size: 18px;
    margin: 0;
    color: white;
    display: flex;
    gap: 8px;
    align-items: center;
}

.close-button {
    cursor: pointer;
    color: #5d5d5d;
}
.close-button:hover {
    color: white;
}

.track-list {
    overflow-y: auto;
    overflow-x: hidden;
    height: 65vh;
    margin-top: 12px;
}
.track-list table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #fff;
  }
  
  .track-list thead {
    color: white;
  }
  
  .track-list th {
    padding: 2px;
    text-align: left;
    font-size: 11px;
  }
  
  .track-list tbody tr {
    cursor: pointer;
    transition: background-color 1s;
  }
  
  .track-list tbody tr:hover {
    background-color: var(--secondary);
  }
  
  .track-list td {
    padding: 8px 2px;
    border-bottom: 1px solid #302f2f;
  }
  
  /* Play button styles */
  .track-list .play-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
    font-size: 16px;
    color: #1ED760;
  }
  
  .track-list .isPlaying {
    background-color: var(--dark-highlight);
    color: #fff;
  }
  
  .playlist-info-sm {
    font-size: 12px;
    margin: 5px 0;
  }
  .playlist-info {
    position: relative;
  }
  .playlist-info h3 {
    font-size: 16px;
    margin: 12px 0px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    }
  .playlist-info h3 a {
    color: white;
    text-decoration: none;
  }
  .playlist-info h3 a:hover {
    text-decoration: underline;
  }

  .track-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 65%;
    display: block;
    margin-bottom: 2px;
  }

  .artist-name {
    font-size: 10px;
    color: #aaa;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 50%;
    display: block;

  }

  .skeleton {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    border-radius: 4px;
}

.copy-button { 
  color: var(--white); 
  cursor: pointer;
  font-size: 16px;
  position: relative;
  top: 2px;
}
.copy-button:hover {
  color: var(--primary);
}

  .drawer .copied-tooltip {
    position: absolute;
    bottom: 95px;
    top: auto;
    background: var(--primary);
    color: var(--white);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
  }

  .drawer .title, .drawer .artist {
    font-size: 12px;
    padding: 10px 0 0 0;
    max-width: 50%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: none;
    justify-content: flex-start;
  }
  .playlist-info img {
    margin-top: 8px;
  }
  .drawer .artist {
    padding-left: 8px;
  }


@keyframes shimmer {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}

/* mobile  */
@media (max-width: 768px) {
  .drawer .artist, .drawer .title {
    display: inline-block;
  }
  .track-name {
    width: 200px;
  }
  .drawer {
    padding: 10px 15px;
    width: var(--drawer-small-width);
  }
  .drawer-open {
    padding-right: var(--drawer-small-width);
  }
  .playlist-info-sm:last-child {
    display: none;
  }
  .track-list {
    height: 55vh;
  }
  .playlist-info h3 {
    font-size: 14px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .playlist-info h3 a {
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .playlist-info img {
    width: 50%;
  }
  .playlist-info-sm { display: none; }
  .playlist-info {
    gap: 10px;
  }
  .playlist-info.skeleton {
    flex-direction: column;
  }
}


@media (min-width: 1200px) {
  .drawer .favorite {
    display: none;
  }
    .track-name {
      width: 230px;
    }
    .drawer {
      width: var(--drawer-large-width);
    }
    .drawer-open {
      padding-right: var(--drawer-large-width);
    }
}