/* SkeletonLoader.css */
.skeleton {
    background: linear-gradient(90deg, #828282 25%, #6f6f6f 50%, #545454 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    border-radius: 4px;
}

@keyframes shimmer {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}
