.toggle-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.toggle-switch__checkbox {
    display: none;
}

.toggle-switch__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.toggle-switch__slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 30px;
}

.toggle-switch__checkbox:checked + .toggle-switch__slider {
    background-color: var(--primary);
}

.toggle-switch__checkbox:checked + .toggle-switch__slider:before {
    transform: translateX(26px);
}


.toggle-switch-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 250px;
    gap: 10px;
    margin: 15px 0;
}

.toggle-switch-label {
    font-size: 1rem;
    user-select: none;
}

.toggle-switch__label {
    cursor: pointer;
}