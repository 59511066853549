:root {
  --primary: #c14dd4;
  --primary-hover: rgb(179, 74, 184);
  --secondary: #2f0ca1;
  --tertiary: #2a9bc0;
  --spotify: #1DB954;
  --background: #1f1e1e;
  --player-background: #282828;
  --page-background: #2b2b2b;
  --text-color: #fff;
  --link-color: #5d5d5d;
  --body-color: #5d5d5d;
  --black: #000;
  --white: #fff;
  --dark-highlight: #321437;
  --main-font: "Roboto", sans-serif;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: var(--page-background);
  font-family: var(--main-font);
  color: var(--body-color);
}
.space-background {
  position: fixed;   /* or absolute depending on your preference */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;       /* ensures it's behind other content */
}

.header {
  position: fixed;
  z-index: 555;
  width: 100%;
  background: var(--background);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  height: 60px;
}

.header .logo {
  padding: 8px 5px;
  display: inline-flex;
  gap: 10px;
  align-items: center;
  user-select: none;
}

.header .logo img {
  width: 130px;
}

.header .logo svg {
  color: var(--link-color);
  font-size: 25px;
  cursor: pointer;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 25px;
}
.header-right button {
  position: relative;
  color: var(--white);
  user-select: none;
  font-size: 14px;
  font-family: sans-serif;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 5px;
  background-image: linear-gradient(45deg, var(--primary), var(--tertiary));
  transition: box-shadow 0.5s;
}

.header-right button:hover {
  box-shadow: rgba(161, 128, 255, 0.6) 0px 0px 1rem 0px;
  animation-timing-function: ease-out;
  animation-duration: 200ms;
}

.header-right button:focus, .header-right button:active {
  top: 1px;
  left: 1px;
}

.filters {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  text-align: right;
}

.filterButton {
  color: var(--white);
  cursor: pointer;
  font-size: 20px;
}

.filter-item {
  display: flex;
  flex-direction: column;
  gap: 1px;
  width: 150px;
  padding-right: 15px;
  border-right: 1px solid #ffffff28;
}
.filter-item:last-child {
  border-right: none;
  padding-right: 0;
}

.filter-item.limit {
  width: 70px;
}

.filters label {
  font-size: 12px;
  color: var(--link-color);
}

.filters select {
  background: var(--background);
  border: none;
  color: var(--link-color);
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}

.user-image {
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  border: 2px solid var(--secondary);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.full-width {
  width: 100%;
}


.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 10px; */
}
.column img {
  width: 100%;
  height: auto;
}
.column .controls {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 10px;
}
.column .controls button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  margin-right: 10px;
  width: 25px;
  height: 25px;
  border-radius: 100px;
  background: #4f4f4f;
  cursor: pointer;
}

.container {
  display: flex;
  width: 100%;
}

.nav-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 90px;
  padding-top: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.nav-container ul {
  padding: 0 15px;
}

.sun-calc-data {
  font-size: 14px;
  text-align: left;
  padding: 0 15px;
  color: #5d5d5d;
}

.nav {
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 5;
  width: 180px;
  height: 100%;
  padding-top: 30px;
  background: var(--background);
  transition: left 0.3s ease-in-out;
}

.nav.show {
  left: 0;
}

.nav li {
  list-style: none;
  padding: 10px;
  cursor: pointer;
  text-align: left;
  user-select: none;
}

.nav li a {
  display: flex;
  gap: 10px;
  color: var(--link-color);
  text-decoration: none;
  transition: 0.2s all ease-in-out
}

.nav li a.active, .nav li a:hover {
  color: var(--primary);
}

.content {
  padding: 25px;
  flex: auto;
  transition: padding 0.3s ease-in-out;
}

.content.nav-open {
  padding-right: 18px;
  padding-left: 190px;
}

.hide {
  display: none;
}

.row {
  display: grid;
  gap: 20px;
  width: 100%;
  transition: 0.1s all ease-in-out;
}

.row.five {
  grid-template-columns: repeat(5, 1fr);
}

.row.four {
  grid-template-columns: repeat(4, 1fr);
}

.row.three {
  grid-template-columns: repeat(3, 1fr);
}

.row.two {
  grid-template-columns: repeat(2, 1fr);
}

/* Large Desktop */
@media (min-width: 1400px) {
  .row.four {
    grid-template-columns: repeat(5, 1fr);
  }
  .row.three {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Extra Large Desktop */
@media (min-width: 1600px) {
  .row.four {
    grid-template-columns: repeat(6, 1fr);
  }
  .row.three {
    grid-template-columns: repeat(5, 1fr);
  }
}

/* Tablet */
@media (max-width: 1024px) and (min-width: 768px) {
  .row.five {
    grid-template-columns: repeat(3, 1fr);
  }

  .row.four,
  .row.three {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Mobile */
@media (max-width: 767px) {
  body {
    overflow-x: hidden;
  }
  .container {
    display: block;
  }
  .content {
    padding: 16px 15px;
  }
  .row.five,
  .row.four,
  .row.three {
    grid-template-columns: repeat(2, 1fr);
  }
  .drawer-open.row.three, .drawer-open.row.four {
    grid-template-columns: repeat(1, 1fr);
  }
  .carousel-container {
    padding-bottom: 0;
  }
  .content .browse-grid {
    padding-left: 0;
  }
}

.login {
  text-align: center;
  padding-bottom: 10px;
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  background: none;
  border: none;
}

.spotify-login {
  margin: 20px auto;
  background: #1DB954;
  border: none;
  border-radius: 999px;
  color: white;
  display: inline-flex;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  cursor: pointer;
  font-size: 22px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.spotify-login:hover {
  background: #1aa34a;
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}


.spotify-login:active {
  transform: translateY(0);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

h2 {
  font-weight: 500;
}

h4 {
  color: white;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  margin: 0;
  padding: 0;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.settings {
  text-align: left;
  padding-left: 25px;
  padding-top: 50px;
  padding-right: 12px;
  color: white;
}

.theme-label {
  display: block;
  font-size: 12px;
}

.select-container {
  position: relative;
  display: inline-block;
}

.select-icon {
  pointer-events: none;
  font-size: 12px;
}

.theme-select {
  cursor: pointer;
  background: var(--background);
  background-image: none;
  border: none;
  color: var(--link-color);
  padding: 10px 1px;
}

.settings h1 {
  font-size: 40px;
  color: white;
}

.settings h2 {
  color: white;
  font-size: 28px;
  margin-top: 40px;
}

.settings p {
  color: white;
}

.settings .action-bar {
  width: 96%;
  background: var(--background);
  padding: 18px;
  display: flex;
  justify-content: flex-start;
  position: sticky;
  bottom: 15px;
  margin-top: 2rem;
}

.settings .save-button {
    background-color: var(--primary);
    color: var(--background);
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    outline: none;
}

.settings .save-button:hover {
    background-color: var(--primary-hover);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.settings .save-button:active {
    transform: scale(0.98); /* Slight "push" effect on click */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.settings .save-button:disabled {
    background-color: #B2B2B2; /* Greyed out */
    cursor: not-allowed;
}

.settings .save-button:focus:not(:active) {
  box-shadow: 0 0 0 3px rgba(29, 185, 84, 0.4); /* Slight glow using Spotify green */
}

.checkbox-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  overflow-y: scroll;
  padding: 20px;
  height: 420px;
}

.checkbox-list label {
  display: block;
  padding: 5px 0;
  color: white;
}

.checkbox {
  display: none; /* hide the default checkbox */
}

.checkbox + label {
  position: relative;
  padding-left: 30px; /* space for custom checkbox */
  cursor: pointer;
}

.checkbox + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #333;
  background: #fff;
}

.checkbox:checked + label::after {
  content: "";
  position: absolute;
  left: 6px;
  top: 3px;
  width: 8px;
  height: 12px;
  border: solid #333;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.checkbox-select-buttons button {
  color: #5d5d5d;
  cursor: pointer;
  margin-bottom: 10px;
}
.checkbox-select-buttons button:hover {
  color:white;
}


.contact-card {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #161515;
  border-radius: 10px;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
  background-color: #1db9540a;
  max-width: 500px;
  margin: 0;
}

.contact-card .user-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin-right: 20px;
  border: 2px solid #e0e0e0;
}
.contact-card a {
  color: white;
  text-decoration: none;
}
.contact-card .user-info {
  flex-grow: 1;
}

.contact-card .user-info p {
  margin: 5px 0;
}

.contact-card .user-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  color: white;
}

.contact-card .user-bio {
  margin-bottom: 10px;
  color: #cacaca;
  font-style: italic;
}

.contact-card .user-activity {
  display: flex;
  font-size: 13px;
  gap: 10px;
}

.contact-card .user-activity span {
  display: flex;
  align-items: center;
}

.contact-card .fas {
  margin-right: 5px;
}


.home {
  color: white;
}

.toast {
  width: 420px;
  position: absolute;
  left: 100px;
  right: 0;
  top: 100px;
  z-index: 999;
  background: #262630;
  display: block;
  margin: 0 auto;
  height: auto;
  padding: 15px;
}

/* mobile  */
@media (max-width: 768px) {
  .home {
    padding: 0;
  }
  
}