.intro {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 15px 8px;
  height: 50vh;
  margin: 25px;
  border-radius: 30px;
}

.intro img {
  width: 180px;
  height: auto;
}

.intro.desktop {
  height: auto;
  max-width: 820px;
  margin: 0 auto;
}

.intro h1 {
  color: var(--text-color);
  font-size: 40px;
  font-weight: 500;
  font-family: var(--main-font);
  font-weight: 500;
  font-style: normal;
  
}

.intro p {
  color: #c2c2c2;
}

.intro .check {
  font-size: 60px;
  color: var(--primary);
}

.login-container {
  display: flex;
  flex-direction: column;
  padding: 0 40px;
}
.get-started {
  border: 3px solid var(--primary);
  color: #fff;
  padding: 12px 16px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 22px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 300px;
  margin: 1rem auto;
  transition: all 0.3s ease-in-out;
}

.get-started:hover {
  box-shadow: var(--dark-highlight) 3px 5px 1rem;
  background: var(--primary);
}

.get-started:focus {
  position: relative;
  top: 2px;
  box-shadow: none;
}

.top {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px;
}

.hero-img {
  height: 420px;
  width: 100%;
  border-radius: 30px;
}

.logged-in .top {
  padding: 70px 0 0;
}

.logged-in .logo {
  display: none;
}

@media (max-width: 768px) {
  .intro {
    height: 91vh;
    width: 100vw;
    margin: 0;
    border-radius: 0;
  }
  .intro.logged-in {
    margin-top: 30px;
  }
}